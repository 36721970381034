import pro1 from "../Assets/NeoPunks.jpg";
import pro2 from "../Assets/Coding-Home1.jpg";
import pro3 from "../Assets/Coding-Home.jpg";

const ProjectCardData = [
  {
    imgsrc: pro1,
    title: "NeoPunks",
    text: "A crypto minting website for NeoPunks.Built with framework React.Languages used Javascript,HTML,CSS,Solidity.Libraries used Hardhat and Chakra.",
    view: "https://6461d09af1d2641680c5abe3--hilarious-biscotti-358920.netlify.app/",
  },
  {
    imgsrc: pro2,
    title: "Project 2",
    text: "This is a description",
    view: "https://www.youtube.com/",
  },
  {
    imgsrc: pro3,
    title: "Project 3",
    text: "This is a description",
    view: "https://www.youtube.com/",
  },
];

export default ProjectCardData;
